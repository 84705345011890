import React from 'react'

import { Router } from "@reach/router"
import { Main } from './Pages/Main'
import {
    Portfolio,
    CharacterDesign,
    Modeling3d,
    Animations,
    Illustration
} from './Pages/Portfolio'
import { About } from './Pages/About'

const App = () => {
    return (
      <div className="App">
          <Router>
            <Main path='/' />
            <Portfolio path='/portfolio' />
            <Animations path='/portfolio/animation' />
            <CharacterDesign path='/portfolio/character-design' />
            <Modeling3d path='/portfolio/3d-modeling' />
            <Illustration path='/portfolio/illustration' />
            <About path='/about' />
          </Router>
      </div>
    )
}

export default App;
