import styled, { css } from 'styled-components'

const PageContent = styled.div`
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: roboto-bold,roboto,sans-serif;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
`;

export {
    PageContent
}