import React, { useState } from 'react';
import {
    BaseContent,
    StyledIconGroup,
    StyledLinkGroup,
    StyledCurrentTopLink,
    StyledTopLink,
    LinkBorder,
    StyledTitle,
    CopyrightGray
} from '../../../style';
import { faInstagram, faArtstation, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Media } from '../../Main'
import { PageContent, Subsection } from './style'
import { FullwidthImage, Divider, SectionTitle, Spacer } from '../../../Components';

const Animations = ({ }: { path: string }) => (
    <BaseContent>
        <StyledLinkGroup>
            <StyledTopLink to="/">Home</StyledTopLink>
            <StyledCurrentTopLink to="/portfolio">Portfolio</StyledCurrentTopLink>
            <StyledTopLink to="/about">About</StyledTopLink>
            <LinkBorder/>
        </StyledLinkGroup>
        <PageContent>
            <StyledTitle
                color='#EB8015'
            >
                Animation
            </StyledTitle>
            <Spacer spacing={64} />
            <FullwidthImage src={`${process.env.PUBLIC_URL}/images/ani110_ass3_tute1_broomhall_sarah_jane_v2_by_ponacho-dd3fa7y.gif`} />
            <FullwidthImage src={`${process.env.PUBLIC_URL}/images/hamsterjump_by_ponacho-dd3fa6e.gif`} />
            <FullwidthImage src={`${process.env.PUBLIC_URL}/images/dd3uf58-a5d67522-005a-43e8-b78d-253a1de15a9a.gif`} />
            <FullwidthImage src={`${process.env.PUBLIC_URL}/images/dd3uf62-fd56ca77-84d1-4d3e-9e3b-0fc1e5d4a8d1.gif`} />
            <Divider />
            <Subsection>
                <SectionTitle title='Showreel' subtitle='2018' />
                <iframe src="https://www.youtube.com/embed/mwCEIyoEl6w" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Subsection>
            <Spacer spacing={64} />
        </PageContent>
        <StyledIconGroup>
            <Media color='gray' type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
            <Media color='gray' type={faArtstation} link='https://www.artstation.com/ponacho' />
            <Media color='gray' type={faTwitter} link='https://twitter.com/FabuPonah' />
            <Media color='gray' type={faInstagram} link='https://www.instagram.com/fabuponah/' />
        </StyledIconGroup>
        <CopyrightGray>© 2019 by Sarah-jane Broomhall</CopyrightGray>
    </BaseContent>
)

export {
    Animations
}