import styled, { css } from 'styled-components'

const StyledDivider = styled.hr`
   width: 100%;
   margin: 40px;
   border: 2px solid;
   color: #dcdcdc;
`

export {
    StyledDivider
}