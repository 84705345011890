import React, { useState } from 'react';
import {
    BaseContent,
    StyledIconGroup,
    StyledLinkGroup,
    StyledCurrentTopLink,
    StyledTopLink,
    LinkBorder,
    StyledTitle,
    CopyrightGray
} from '../../../style';
import { faInstagram, faArtstation, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Media } from '../../Main'
import { PageContent, Subsection } from './style';
import { FullwidthImage, HalfwidthImage,  Divider, SectionTitle, Spacer } from '../../../Components';

const Modeling3d = ({ }: { path: string }) => (
    <BaseContent>
        <StyledLinkGroup>
            <StyledTopLink to="/">Home</StyledTopLink>
            <StyledCurrentTopLink to="/portfolio">Portfolio</StyledCurrentTopLink>
            <StyledTopLink to="/about">About</StyledTopLink>
            <LinkBorder/>
        </StyledLinkGroup>
        <PageContent>
            <StyledTitle
                color='#30B5FC'
            >
                3D Modelling
            </StyledTitle>
             <PageContent>
            <Spacer spacing={64} />
            <HalfwidthImage src={`${process.env.PUBLIC_URL}/images/MDU115.1_Tute01_BROOMHALL_Sarah-Jane.jpg`} />
            <HalfwidthImage src={`${process.env.PUBLIC_URL}/images/MDU115.1_Tute01_BROOMHALL_Sarah-Jane_altview.jpg`} />
            <Spacer spacing={20} />
            <SectionTitle fullwidth title='Primitives Render' subtitle='2018' />
            </PageContent>
            <Divider />
            <Subsection>
                <SectionTitle title='3D Asset Portfolio' subtitle='2018' />
                <iframe  src="https://www.youtube.com/embed/QNp7iYhbQPc" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Subsection>
            <Spacer spacing={20} />
            <Subsection>
                <SectionTitle title={`Pirate's Gold project`} subtitle='2018' />
                <iframe  src="https://www.youtube.com/embed/GEffn_zrAsA" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Subsection>
            <Spacer spacing={64} />
        </PageContent>
        <StyledIconGroup>
            <Media color='gray' type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
            <Media color='gray' type={faArtstation} link='https://www.artstation.com/ponacho' />
            <Media color='gray' type={faTwitter} link='https://twitter.com/FabuPonah' />
            <Media color='gray' type={faInstagram} link='https://www.instagram.com/fabuponah/' />
        </StyledIconGroup>
        <CopyrightGray>© 2019 by Sarah-jane Broomhall</CopyrightGray>
    </BaseContent>
)


export {
    Modeling3d
}