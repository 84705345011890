import styled, { css } from 'styled-components'
import { Link } from "@reach/router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: bottom;
    background-size: cover;
    background-image: url('${process.env.PUBLIC_URL}/images/thrr.png');
`;

const StyledIcon = styled(FontAwesomeIcon)`
   &:hover {
       opacity: 1 !important;
   }
`

const LinkBorder = styled.hr`
    width: 150px;
    margin-top: 10px;
    border: 1px solid lightgray;
`;

const StyledLinkGroup = styled.div`
   margin-bottom: 25px;
`

const StyledLink = styled(Link)`
    margin-right: 12px;
    margin-left: 12px;
   text-decoration: none;
   font-family: roboto-bold,roboto,sans-serif;
    font-size: 21px;
    color: #256FB0;
    font-weight: 600;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: #FFFFFF;
   }

   @media all and (max-width: 736px) { 
        font-size: 14px;
    }
`

const StyledCurrentLink = styled(StyledLink)`
    color: #EB8015;
`

const StyledCurrentTopLink = styled(StyledLink)`
    color: #EB8015;

    &:hover {
        color: #30B5FC;
    }
`

const StyledTopLink = styled(StyledLink)`
    &:hover {
        color: #30B5FC;
    }
`

const StyledTitle = styled.div<{color: String}>`
    font-weight: 800;
    font-size: 50px;
    font-family: 'IBM Plex Sans', sans-serif;
    ${(props) => {
        return css`
            color: ${props.color};
        `
    }}
    text-transform: uppercase;
    letter-spacing: 6px;
    word-break: break-word;

    @media all and (max-width: 736px) { 
        font-size: 24px;
    }
`

const StyledSubtitle = styled.div`
    margin-top: 12px;
    font-weight: 600;
    font-family: roboto-bold,roboto,sans-serif;
    font-size: 21px;
    color: #EB8015;

    @media all and (max-width: 736px) { 
        font-size: 14px;
    }
`

const StyledCenteredItems = styled.div`
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   display: flex;
    align-items: center;
`


const StyledTitleSection = styled.div`
   width: 60%;
   text-align: center;
   max-width: 600px;
   opacity: 0.9;
   border-radius: 100px;
   box-shadow: 0px 0px 10px 0px rgba(75,122,179,1);
   padding: 60px;
   background-color: white;

   @media all and (max-width: 736px) { 
        padding: 40px;
    }
`

const StyledIconGroup = styled.div`
   margin-top: 25px;

   svg {
       margin-left: 4px;
       margin-right: 4px;
   }

   &:hover {
       svg {
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;
       }
   }
`

const BaseContent = styled.div`
    flex-direction: column;
    align-items: center;
     display: flex;
     width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    left: 0;
    top: 0;
    position: absolute;
    min-height: calc(100% - 48px);
`;

const CopyrightLight = styled.div`
    font-family: roboto-bold,roboto,sans-serif;
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    color: #FFFFFF; 
    text-align: center;
`
const CopyrightWhite = styled.div`
    font-family: roboto-bold,roboto,sans-serif;
    margin-top: 20px;
    positon: absolute;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF; 
`

const CopyrightGray = styled.div`
    font-family: roboto-bold,roboto,sans-serif;
    margin-top: 20px;
    positon: absolute;
    margin-left: auto;
    margin-right: auto;
    color: #969696;
`

export {
    CopyrightGray,
    CopyrightLight,
    CopyrightWhite,
    StyledTopLink,
    BaseContent,
    StyledCurrentLink,
    StyledLink,
    StyledLinkGroup,
    StyledSubtitle,
    StyledTitle,
    StyledTitleSection,
    StyledIcon,
    StyledIconGroup,
    StyledCenteredItems,
    StyledBackground,
    StyledCurrentTopLink,
    LinkBorder
}