import React from 'react'
import styled from 'styled-components'

const StyledFullwidthImage = styled.img`
    width: calc(100% - 20px);
    padding: 10px;
`

const StyledHalfwidthImage = styled.img`
    width: calc(50% - 20px);
    padding: 10px;

    @media all and (max-width: 736px) { 
        width: calc(100% - 20px);
    }
`

const StyledFullwidthTitle = styled.p`
    width: calc(100% - 20px);
    padding: 10px;
`

const StyledHalfwidthTitle = styled.p`
    width: calc(50% - 20px);
    padding: 10px;

    @media all and (max-width: 736px) { 
        width: calc(100% - 20px);
    }
`

const ImageContainer = styled.div`
    p {
        text-align: center;
         margin: 0;
        font-weight: 400;
        font-family: roboto-bold,roboto,sans-serif;
        font-size: 18px;
        color: #696969;

        @media all and (max-width: 736px) { 
            font-size: 14px;
        }
    }
`

const HalfwidthImage = (props: any) => {
    if (props.title) {
        return (
            <ImageContainer>
                <StyledHalfwidthImage {...props} />
                <StyledHalfwidthTitle>{props.title}</StyledHalfwidthTitle>
            </ImageContainer>
        )
    }

    return (
        <StyledHalfwidthImage {...props} />
    )
}

const FullwidthImage = (props:any) => {
    if (props.title) {
        return (
            <ImageContainer>
                <StyledFullwidthImage {...props} />
                <StyledFullwidthTitle>{props.title}</StyledFullwidthTitle>  
            </ImageContainer>
        )
    }
    
    return (
        <StyledFullwidthImage {...props} />
    )
}

export {
    FullwidthImage,
    HalfwidthImage
}