import React, { useState } from 'react';
import { StyledImageLink, PageContent } from './style'
import {
    BaseContent,
    StyledIconGroup,
    StyledLinkGroup,
    StyledCurrentTopLink,
    StyledTopLink,
    LinkBorder,
    CopyrightGray
} from '../../style';
import { faInstagram, faArtstation, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Media } from '../Main'

const Portfolio = ({ }: { path: string }) => (
    <BaseContent>
        <StyledLinkGroup>
            <StyledTopLink to="/">Home</StyledTopLink>
            <StyledCurrentTopLink to="/portfolio">Portfolio</StyledCurrentTopLink>
            <StyledTopLink to="/about">About</StyledTopLink>
            <LinkBorder/>
        </StyledLinkGroup>
        <PageContent>
            <ImageLink
                href='/portfolio/character-design'
                title='Character Design'
                baseImage='14a.png'
                hoverImage='9a.png'
                color='#30B5FC'
            />
            <ImageLink
                href='/portfolio/animation'
                title='Animation'
                baseImage='ani110_ass3_tute1_broomhall_sarah_jane_v2_by_ponacho-dd3fa7y.gif'
                hoverImage='21ba20b5b9e51b7ed2d4357cd867d4f3.png'
                color='#EB8015'
            />
            <ImageLink
                href='/portfolio/3d-modeling'
                title='3D Modelling'
                baseImage='MDU115.1_Tute01_BROOMHALL_Sarah-Jane.jpg'
                hoverImage='view3.png'
                color='#30B5FC'
            />
            <ImageLink
                href='/portfolio/illustration'
                title='Illustration'
                baseImage='ponah_in_flowerland_illustration_by_ponacho-dclfkvn.png'
                hoverImage='25_2_orig.png'
                color='#EB8015'
            />
            <ImageLink
                href='/portfolio'
                title='Sketchbook (WIP)'
                baseImage='maxresdefault.png'
                hoverImage='maxresdefault.png'
                color='#30B5FC'
            />
            <ImageLink
                href='/portfolio'
                title='Storyboarding (WIP)'
                baseImage='maxresdefault.png'
                hoverImage='maxresdefault.png'
                color='#EB8015'
            />
        </PageContent>
        <StyledIconGroup>
            <Media color='gray' type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
            <Media color='gray' type={faArtstation} link='https://www.artstation.com/ponacho' />
            <Media color='gray' type={faTwitter} link='https://twitter.com/FabuPonah' />
            <Media color='gray' type={faInstagram} link='https://www.instagram.com/fabuponah/' />
        </StyledIconGroup>
        <CopyrightGray>© 2019 by Sarah-jane Broomhall</CopyrightGray>
    </BaseContent>
)

interface ImageLinkProps {
    href: string,
    title: string,
    baseImage: string,
    hoverImage: string,
    color: string
}

const ImageLink = ({ href, title, baseImage, hoverImage, color }: ImageLinkProps) => {
    const [currentImage, setCurrentImage] = useState(baseImage)

    return (
        <StyledImageLink
            to={href}
            color={color}
            onMouseOver={() => setCurrentImage(hoverImage)}
            onMouseOut={() => setCurrentImage(baseImage)}
            backgroundImage={currentImage}
        >
            <span>
                {title}
            </span>
        </StyledImageLink>
    )
}

export {
    Portfolio
}