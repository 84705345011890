import styled, { css } from 'styled-components'

const PageContent = styled.div`
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: roboto-bold,roboto,sans-serif;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
`;

const GalleryContainer = styled.div`
    display: block;
    minHeight: 1px;
    width: 100%;
`;

export {
    GalleryContainer,
    PageContent
}