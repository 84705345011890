import React, { Component } from 'react';
import { Link } from "@reach/router"
import {
    StyledSubtitle,
    StyledTitle,
    StyledIconGroup,
    StyledBackground,
    StyledIcon,
    StyledCenteredItems,
    StyledTitleSection,
    StyledLinkGroup,
    StyledLink,
    StyledCurrentLink,
    CopyrightLight
} from '../../style';
import { faInstagram, faArtstation, faLinkedin, faTwitter, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Main = ({}: { path: string }) => {
    return (
        <StyledBackground>
            <StyledCenteredItems>
                <StyledLinkGroup>
                    <StyledCurrentLink to="/">Home</StyledCurrentLink>
                    <StyledLink to="/portfolio">Portfolio</StyledLink>
                    <StyledLink to="/about">About</StyledLink>
                </StyledLinkGroup>
                <StyledTitleSection>
                    <StyledTitle color='#256FB0'>
                        Sarah-Jane
                    </StyledTitle>
                    <StyledTitle color='#256FB0'>
                        Broomhall
                    </StyledTitle>
                    <StyledSubtitle>
                        Illustrator | Character designer | Animator
                    </StyledSubtitle>
                </StyledTitleSection>
                <StyledIconGroup>
                    <Media type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
                    <Media type={faArtstation} link='https://www.artstation.com/ponacho' />
                    <Media type={faTwitter} link='https://twitter.com/FabuPonah' />
                    <Media type={faInstagram} link='https://www.instagram.com/fabuponah/' />
                </StyledIconGroup>
            </StyledCenteredItems>
            <CopyrightLight>© 2019 by Sarah-jane Broomhall</CopyrightLight>
        </StyledBackground>
    );
}

const Media = ({ type, link, color = 'white' }: { type: IconDefinition, link: string, color?: string }) => {
    return (
        <a href={link}>
            <StyledIcon icon={type} mask={faCircle} size='2x' color={color} transform="shrink-6" />
        </a>
    );
}

export {
    Main,
    Media
}