import React from 'react';
import {StyledSectionTitle} from "./style";

const SectionTitle = ({ title, subtitle, fullwidth }: { fullwidth?: boolean, title: string, subtitle?: string }) => (
    <StyledSectionTitle fullwidth={fullwidth}>
        <h1>{title}</h1>
        <p>{subtitle}</p>
    </StyledSectionTitle>
)

export {
    SectionTitle
}