import styled, { css } from 'styled-components'

const PageContent = styled.div`
   background-color: #96DAFF;
   width: 100%;
   display: flex;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);

    @media all and (max-width: 1024px) { 
         margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: inherit;
        transform: inherit;
    }
`;

const StyledProfilePicture = styled.div`
    @media all and (max-width: 1024px) { 
        height: 250px;
        width: 100%;
        background-position: top;
    }

    width: 60%;
    background-position: center;
    background-size: cover;
    background-image: url('${process.env.PUBLIC_URL}/images/DJ2y0RWVwAUZX8jsd.jpg');
`

const StyledAbout = styled.div`
     @media all and (max-width: 1024px) { 
          width: 100%;
          padding: 20px 0px;
    }

    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const StyledAboutText = styled.div`
    @media all and (max-width: 1024px) { 
        margin-top: 20px;
        margin-bottom: 20px;
    }
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    background-color: #FFFFFF;
    border: 7px solid #EBCF75;
    max-width: 400px;
    box-shadow: 0px 0px 5px 1px rgba(75,123,166,1);
    font-family: 'Didact Gothic', sans-serif;
`

const StyledEmailText = styled.div`
    color: #969696; 
`

const StyledTitleText = styled.div`
    color: #EB8015; 
`

export {
    StyledTitleText,
    StyledEmailText,
    StyledAboutText,
    StyledAbout,
    StyledProfilePicture,
    PageContent
}