import React, { Component } from 'react';
import { Link } from "@reach/router"

import {
    BaseContent,
    StyledIconGroup,
    StyledLinkGroup,
    StyledCurrentTopLink,
    StyledTopLink,
    StyledTitle,
    LinkBorder,
    CopyrightGray,
    CopyrightLight,
    CopyrightWhite
} from '../../style';
import {
    PageContent,
    StyledProfilePicture,
    StyledAbout,
    StyledAboutText,
    StyledEmailText,
    StyledTitleText
} from './style';
import { faInstagram, faArtstation, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Media } from '../Main'

const About = ({ }: { path: string }) => {
    return (
        <BaseContent>
            <StyledLinkGroup>
                <StyledTopLink to="/">Home</StyledTopLink>
                <StyledTopLink to="/portfolio">Portfolio</StyledTopLink>
                <StyledCurrentTopLink to="/about">About</StyledCurrentTopLink>
                <LinkBorder/>
            </StyledLinkGroup>
            <PageContent>
                <StyledProfilePicture />
                <StyledAbout>
                    <StyledTitle color='#256FB0'>
                        About me
                    </StyledTitle>
                    <StyledAboutText>
                        <StyledTitleText>
                            Sarah is an Australian artist from Brisbane QLD.
                        </StyledTitleText>
                        <br />
                        <div>
                            She enjoys drawing and designing cute and strange creatures, working with colours and experimenting. She also likes to learn new skills and is currently furthering her knowledge at SAE Institute Brisbane.
                        </div>
                        <div>
                            Sarah uses a range of programs from Adobe Suite to Clip Studio Paint and Procreate, however an old favorite of hers to work in is Paint Tool Sai.
                        <div>
                        <br />
                        </div>
                            In her free time, Sarah likes to read graphic novels, draw anthro animals and try new food. She can often be found sitting in the botanic gardens watching the clouds go by.
                        </div>
                        <br/>
                        <br />
                        <StyledEmailText>
                            Email | sjbroomhall@hotmail.com
                        </StyledEmailText>
                    </StyledAboutText>
                    <StyledIconGroup>
                        <Media color='white' type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
                        <Media color='white' type={faArtstation} link='https://www.artstation.com/ponacho' />
                        <Media color='white' type={faTwitter} link='https://twitter.com/FabuPonah' />
                        <Media color='white' type={faInstagram} link='https://www.instagram.com/fabuponah/' />
                    </StyledIconGroup>
                    <CopyrightWhite>© 2019 by Sarah-jane Broomhall</CopyrightWhite>
                </StyledAbout>
            </PageContent> 
        </BaseContent>
    )
}

export {
    About
}
