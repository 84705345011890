import styled, { css } from 'styled-components'

const Spacer = styled.div<{spacing: number}>`
   width: 100%;
    ${(props: {spacing: number}) => {
        return css`
            height: ${props.spacing}px;
        `
    }}
`

export {
    Spacer
}