import React  from 'react';
import {
    BaseContent,
    StyledIconGroup,
    StyledLinkGroup,
    StyledCurrentTopLink,
    StyledTopLink,
    LinkBorder,
    StyledTitle,
    CopyrightGray
} from '../../../style';
import { SectionTitle, Divider, FullwidthImage, HalfwidthImage, Spacer } from '../../../Components'
import { faInstagram, faArtstation, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Media } from '../../Main'
import { PageContent } from './style'

const CharacterDesign = ({ }: { path: string }) => (
    <BaseContent>
        <StyledLinkGroup>
            <StyledTopLink to="/">Home</StyledTopLink>
            <StyledCurrentTopLink to="/portfolio">Portfolio</StyledCurrentTopLink>
            <StyledTopLink to="/about">About</StyledTopLink>
            <LinkBorder/>
        </StyledLinkGroup>
        <PageContent>
            <StyledTitle
                color='#30B5FC'
            >
                Character Design
            </StyledTitle>
            <PageContent>
                <Spacer spacing={64} />
                <SectionTitle title='Paka The Alpaca' subtitle='2019'/>
                <FullwidthImage src={`${process.env.PUBLIC_URL}/images/14a.png`} />
                <FullwidthImage src={`${process.env.PUBLIC_URL}/images/14c.png`} />
                <HalfwidthImage src={`${process.env.PUBLIC_URL}/images/9a.png`} />
                <HalfwidthImage src={`${process.env.PUBLIC_URL}/images/9b.png`} />
                <Divider />
                <HalfwidthImage src={`${process.env.PUBLIC_URL}/images/17.png`} title='Apa (2019)'/>
            </PageContent>
            <Spacer spacing={64} />
        </PageContent>
        <StyledIconGroup>
            <Media color='gray' type={faLinkedin} link='https://www.linkedin.com/in/sarah-jane-broomhall/' />
            <Media color='gray' type={faArtstation} link='https://www.artstation.com/ponacho' />
            <Media color='gray' type={faTwitter} link='https://twitter.com/FabuPonah' />
            <Media color='gray' type={faInstagram} link='https://www.instagram.com/fabuponah/' />
        </StyledIconGroup>
        <CopyrightGray>© 2019 by Sarah-jane Broomhall</CopyrightGray>
    </BaseContent>
)

export {
    CharacterDesign
}