import styled, { css } from 'styled-components'
import { Link } from "@reach/router"

const StyledImageLink = styled(Link) <{ backgroundImage: string, color: string}>`
    width: calc(33% - 24px);
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-image 0.2s;
    background-size: cover;
    background-position: center;
    margin: 12px;
    text-align: center;
    border-radius: 8px;

    @media all and (max-width: 1024px) { 
        height: 200px;
         width: 100%;
    }

    ${(props) => {
        return css`
            background-image: url('${process.env.PUBLIC_URL}/images/${props.backgroundImage}');    

            &:hover {
                span {
                    width: 100%;
                    height: 100%;
                    transition: opacity 0.2s;
                    line-height: 370px;
                    background-color:rgba(255, 255, 255, 0.6);
                    
                      @media all and (max-width: 1024px) { 
                        line-height: 200px;
                    }
                }

            }

            span {
                line-height: 60px;
                width: 100%;
                position: relative;
                transition: background-color 0.2s;
                background-color:rgba(255, 255, 255, 0.9);
                color: ${props.color};
                font-weight: 800;
                font-size: 30px;
                padding: 2px 6px;
                font-family: 'IBM Plex Sans', sans-serif;
            }
        `
    }}
`;

const PageContent = styled.div`
    max-width: 1600px;
   margin-left: auto;
   margin-right: auto;
    justify-content: center;
        font-family: roboto-bold,roboto,sans-serif;

       display: flex;
   flex-flow: row wrap;
    width: 100%;
`;

export {
    PageContent,
    StyledImageLink
}